.container.sidebar {
  padding-top: var(--spacing-smaller-2);
  padding-bottom: var(--spacing-smaller-2);
  min-width: max-content;
  overflow: auto;
}

.container.header.large {
  padding: var(--spacing-smaller-1);
}

.container.header.medium {
  padding: var(--spacing-smaller-1);
}

.container.header.medium.overlay .overlayContent {
  padding-top: var(--spacing-smaller-1);
}

.container.header.small {
  padding: var(--spacing-smaller-2);
}

.container.header.large.overlay .banner,
.container.header.medium.overlay .banner,
.container.header.small.overlay .banner {
  padding-bottom: var(--spacing-smaller-2);
}

.container.header.small {
  border-bottom: 1px dashed var(--color-neutral-lighter-3);
}

.container.header.small.overlay .overlayContent {
  border-top: 1px dashed var(--color-neutral-lighter-3);
}

.container.header .banner {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  align-items: stretch;
}

.container.overlay {
  min-height: 100svh;
}

.logo {
  text-decoration: none;
  color: initial;
}

.date {
  padding-top: var(--spacing);
  padding-bottom: var(--spacing-smaller-2);
  font-size: var(--font-size-larger-1);
  color: var(--color-text-label);
  white-space: nowrap;
}

.date.clickable {
  cursor: pointer;
}

.container.header .navItems {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
}

.navItem,
.navTabItem {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  color: var(--color-neutral);
  text-decoration: none;
  text-align: center;
}

.navItem :global(.react-tooltip),
.navTabItem :global(.react-tooltip) {
  background-color: var(--color-text-nav-hover);
}

.container.header .navItem {
  font-size: var(--font-size-larger-2);
}

.container.sidebar .navItem {
  padding: var(--spacing-smaller-2) 0;
  font-size: var(--font-size-larger-3);
}

.date.clickable:hover,
.navItem:hover {
  color: var(--color-text-nav-hover);
  text-decoration: underline;
}

.navItem:global(.active),
.navTabItem:global(.active) {
  color: var(--color-text-nav-active);
  font-weight: 700;
}

.navItem:global(.active) :global(.react-tooltip),
.navTabItem:global(.active) :global(.react-tooltip) {
  background-color: var(--color-text-nav-active);
}

.menu {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: var(--color-primary-darker-1);
  cursor: pointer;
  justify-self: flex-end;
  align-self: center;
}

.overlayContent {
  overflow: auto;
  height: calc(100svh - 76px);
}

.overlayContent .navItem,
.overlayContent .navTabItem {
  background: white;
  padding: var(--spacing-smaller-2);
  border-bottom: 1px dashed var(--color-neutral-lighter-3);
  text-align: left;
  justify-content: flex-start;
}

.overlayContent .navTabItem {
  padding-left: var(--spacing);
}

.foot {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.container.sidebar .foot {
  padding: var(--spacing) 0;
}

.env {
  margin-top: var(--spacing);
  font-size: 12px;
  color: var(--color-neutral-lighter-4);
  text-transform: uppercase;
}

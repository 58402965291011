.container {
  margin: var(--spacing);
}

.container svg {
  display: block;
}

.panel {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pointer {
  position: fixed;
}
.container {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 99999;
}

.devtools {
  background: hsla(0, 0%, 23%, 0.05);
  backdrop-filter: blur(2px);
  border-radius: var(--border-radius-panel);
  padding: var(--spacing-smaller-3);
  font-size: var(--font-size-smaller-2);
  color: var(--color-neutral-lighter-2);
}

.logo {
  font-family: 'Nunito Sans', sans-serif;
  text-transform: uppercase;
  font-size: 2rem;
  line-height: 2.125rem;
  letter-spacing: 0.8px;
}

.seattle {
  color: var(--color-primary);
  font-weight: 400;
}

.symphony {
  font-size: 2.125rem;
  font-weight: 900;
}

.chorale {
  font-weight: 500;
}

.logo.large {
  text-align: center;
}

.logo.small {
  font-size: 1rem;
  line-height: 1rem;
  letter-spacing: 0.4px;
}

.logo.small .symphony {
  font-size: 1rem;
}

.button {
  display: inline-block;
  padding: 2px 8px;
  background-color: var(--color-background-panel);
  border-radius: var(--border-radius-2);
  border: 1px solid;
  font-size: var(--font-size-action-button);
  line-height: var(--font-size);
  font-weight: 700;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 1px;
  opacity: 0.9;
  transition: color 100ms ease-out, background-color 100ms ease-out,
    opacity 100ms ease-out;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 6px;
}

.button.default {
  color: var(--color-action-button-default);
  border-color: var(--color-border-action-button-default);
}

.button.danger {
  color: var(--color-danger);
  border-color: var(--color-danger);
}

.button.success {
  color: var(--color-success);
  border-color: var(--color-success);
}

.button.warning {
  color: var(--color-warning);
  border-color: var(--color-warning);
}

.button.primary {
  color: var(--color-primary);
  border-color: var(--color-primary);
}

.button.primary-darker-1 {
  color: var(--color-primary-darker-1);
  border-color: var(--color-primary-darker-1);
}

.button.secondary {
  color: var(--color-secondary);
  border-color: var(--color-secondary);
}

.button:not(.disabled):hover,
.button.selected {
  color: var(--color-white);
  opacity: 1;
}

.button:not(.disabled).default:hover,
.button.default.selected {
  background-color: var(--color-border-action-button-default);
}

.button:not(.disabled).danger:hover,
.button.danger.selected {
  background-color: var(--color-danger);
}

.button:not(.disabled).success:hover,
.button.success.selected {
  background-color: var(--color-success);
}

.button:not(.disabled).warning:hover,
.button.warning.selected {
  background-color: var(--color-warning);
}

.button:not(.disabled).primary:hover,
.button.primary.selected {
  background-color: var(--color-primary);
}

.button:not(.disabled).primary-darker-1:hover,
.button.primary-darker-1.selected {
  background-color: var(--color-primary-darker-1);
}

.button:not(.disabled).secondary:hover,
.button.secondary.selected {
  background-color: var(--color-secondary);
}

.button.disabled {
  opacity: 0.4;
  cursor: default;
}

.button svg {
  margin: 1px 0;
}
:root {
  --color-primary-lighter-3: #ffcfe1;
  --color-primary-lighter-2: #ff9ec4;
  --color-primary-lighter-1: #f868a7;
  --color-primary: #ed018c;
  --color-primary-darker-1: #ad1967;
  --color-primary-darker-2: #711b45;
  --color-primary-darker-3: #3a1525;

  --color-secondary-lighter-4: #d5e4e9;
  --color-secondary-lighter-3: #aacad4;
  --color-secondary-lighter-2: #7fb0be;
  --color-secondary-lighter-1: #5196a9;
  --color-secondary: #047d95;
  --color-secondary-darker-1: #136375;
  --color-secondary-darker-2: #174a57;
  --color-secondary-darker-3: #15333b;
  --color-secondary-darker-4: #101d20;

  --color-neutral-lighter-6: #fafafa;
  --color-neutral-lighter-5: #f5f5f5;
  --color-neutral-lighter-4: #dddddd;
  --color-neutral-lighter-3: #cacaca;
  --color-neutral-lighter-2: #979797;
  --color-neutral-lighter-1: #676767;
  --color-neutral: #3b3b3b;
  --color-neutral-darker-1: #2d2d2d;
  --color-neutral-darker-2: #202020;
  --color-neutral-darker-3: #141414;

  --color-white: #ffffff;
  --color-black: #000000;
  --color-danger: #d60b1c;
  --color-success: #2e8b57;
  --color-warning: #ef5328;

  /* -- */

  --color-background-app: var(--color-neutral-lighter-5);
  --color-background-panel: var(--color-white);
  --color-background-panel-image: var(--color-neutral-lighter-3);
  --color-background-panel-default-title: var(--color-primary-darker-2);
  --color-background-panel-danger-title: var(--color-danger);
  --color-background-modal: var(--color-white);
  --color-background-grid-row-hover: var(--color-neutral-lighter-6);

  --color-border-panel-image: var(--color-neutral-lighter-3);
  --color-border-panel-hover: var(--color-primary-darker-1);
  --color-border-panel-default: var(--color-primary-darker-2);
  --color-border-panel-danger: var(--color-danger);
  --color-border-action-button-default: var(--color-secondary-darker-1);
  --color-border-action-button-danger: var(--color-danger);

  --color-text: var(--color-neutral);
  --color-text-label: var(--color-neutral-lighter-2);
  --color-text-nav-active: var(--color-primary-darker-1);
  --color-text-nav-hover: var(--color-primary-darker-2);
  --color-text-header-back: var(--color-primary-darker-1);
  --color-text-tab-active: var(--color-primary-darker-1);
  --color-text-tab-hover: var(--color-primary-darker-2);
  --color-text-tab-inactive: var(--color-neutral-lighter-2);
  --color-text-inactive: var(--color-neutral-lighter-3);

  --color-action-button-default: var(--color-secondary);
  --color-action-button-default-hover: var(--color-secondary-lighter-2);
  --color-action-button-danger: var(--color-secondary);

  /* -- */

  --font-size-smaller-3: 0.7rem;
  --font-size-smaller-2: 0.8rem;
  --font-size-smaller-1: 0.9rem;
  --font-size-base: 16px;
  --font-size: 1rem;
  --font-size-larger-1: 1.1rem;
  --font-size-larger-2: 1.2rem;
  --font-size-larger-3: 1.4rem;
  --font-size-larger-4: 1.6rem;
  --font-size-larger-5: 1.8rem;
  --font-size-larger-6: 2rem;

  /* -- */

  --font-size-title: var(--font-size-larger-4);
  --font-size-label-large: var(--font-size);
  --font-size-label-small: var(--font-size-smaller-2);
  --font-size-action-button: var(--font-size-smaller-3);

  /* -- */

  --line-height-tight: 100%;

  /* -- */

  --spacing-smaller-4: 0.25rem; /* 4px */
  --spacing-smaller-3: 0.5rem; /* 8px */
  --spacing-smaller-2: 0.75rem; /* 12px */
  --spacing-smaller-1: 1rem; /* 16px */
  --spacing: 1.5rem; /* 24px */
  --spacing-larger-1: 2rem; /* 32px */
  --spacing-larger-2: 2.25rem; /* 40px */

  /* -- */

  --border-radius-1: 2px;
  --border-radius-2: 4px;
  --border-radius-3: 6px;
  --border-radius-4: 8px;
  --border-radius-round: 50%;

  /* -- */

  --border-radius-panel: var(--border-radius-3);

  --border-light: 0.5px dashed var(--color-neutral-lighter-3);

  --box-shadow-hover: 1px 1px 1px hsl(0deg 0% 70% / 33%),
    2px 3px 3px hsl(0deg 0% 70% / 33%);
  --box-shadow-focus: 1px 1px 1px hsl(0deg 0% 70% / 33%),
    2px 3px 3px hsl(0deg 0% 70% / 33%), 3px 5px 5px hsl(0deg 0% 70% / 33%);

  --amplify-components-fieldcontrol-border-color: var(
    --color-neutral-lighter-2
  );

  --rt-color-white: #fff;
  --rt-color-dark: #222;
  --rt-color-success: #8dc572;
  --rt-color-error: #be6464;
  --rt-color-warning: #f0ad4e;
  --rt-color-info: #337ab7;
  --rt-opacity: 0.9;
  --rt-transition-show-delay: 0.15s;
  --rt-transition-closing-delay: 0.15s;
}

a {
  color: var(--color-primary-darker-2);
}

.label-small,
.grid-header-cell {
  color: var(--color-text-label);
  font-size: var(--font-size-label-small);
  font-weight: 600;
  text-transform: uppercase;
  display: flex;
  align-items: center;
}

.label-large {
  color: var(--color-text-label);
  font-size: var(--font-size-label-large);
  font-weight: 600;
  text-transform: uppercase;
  display: flex;
  align-items: center;
}

.grid-header-cell {
  padding-bottom: var(--spacing-smaller-3);
}

.grid-cell {
  padding: var(--spacing-smaller-4) 0;
  font-size: var(--font-size-smaller-1);
  opacity: 0;
}

.grid-header-cell,
.grid-cell {
  border-bottom: var(--border-light);
}

.inactive {
  color: var(--color-text-inactive);
}

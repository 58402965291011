body {
  margin: 0;
  background: var(--color-background-app);
  font-size: var(--font-size-base);
  font-family: 'Lato', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-height: 100svh;
  max-width: 100svw;
}

body * {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

[data-amplify-authenticator][data-variation='modal'] {
  background: var(--color-background-app);
}

[data-amplify-authenticator] [data-amplify-router] {
  border: 0;
  border-radius: 4px;
}

form[data-amplify-authenticator-confirmverifyuser] input[type="number"]::-webkit-outer-spin-button,
form[data-amplify-authenticator-confirmverifyuser] input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

form[data-amplify-authenticator-confirmverifyuser] input[type="number"] {
  -moz-appearance: textfield;
}

.authenticator__header {
  padding: 2rem;
  display: flex;
  justify-content: center;
}

.amplify-button--primary {
  background: var(--color-primary);
}

.shimmer {
  display: inline-block;
  -webkit-mask: linear-gradient(-60deg, #000 30%, #0005, #000 70%) right/300%
    100%;
  background-repeat: no-repeat;
  animation: shimmer 2s infinite;
}

@keyframes shimmer {
  100% {
    -webkit-mask-position: left;
  }
}

.container,
.loading {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
}

.container {
  justify-content: space-between;
  align-items: stretch;
}

.loading {
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.reset,
.logoSpinner {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logoSpinner {
  flex-direction: column;
}

.reset {
  flex-direction: column;
  padding-top: var(--spacing-larger-2);
  font-size: var(--font-size-larger-3);
}

.resetButton {
  margin-top: var(--spacing-larger-2);
  color: white;
  border: 1px solid var(--color-primary-2);
  border-radius: var(--border-radius-panel);
  background-color: var(--color-primary-darker-2);
  padding: 0.6rem 1.6rem;
  font-size: var(--font-size-larger-2);
  font-weight: 600;
  text-transform: uppercase;
  cursor: pointer;
}

.main {
  height: 100%;
  flex-grow: 1;
  overflow: hidden;
  position: relative;
}

.container.navAsSidebar {
  flex-direction: row;
  padding: var(--spacing);
}

.container.navAsHeader {
  flex-direction: column;
}

.container.navAsSidebar .main {
  padding-right: var(--spacing);
}

.container.navAsHeader .main {
  max-width: 100%;
}

.container.navAsHeader .main {
  padding-right: var(--spacing-smaller-1);
  padding-bottom: var(--spacing-smaller-1);
  padding-left: var(--spacing-smaller-1);
}

.container.navAsHeader.small .main {
  padding: 0;
}
